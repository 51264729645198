import React, { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import ShowHeadAvatar from './ShowHeadAvatar';
import { Bar } from 'react-chartjs-2';
import ImageLoader from '../ImageLoader';
import dayjs from 'dayjs';
import { getHairComparisonPercentage } from '../../utlis/calculations';
import { useTranslation } from '../../contexts/TranslationContext';


const ThreexCloseupImageView = ({ analysis, allAnalysis, setReady }) => {
	const history = useHistory();
	const { translate } = useTranslation();

	const { patient_id, analysis_id, subType } = useParams();
	const analysis_id_int = parseInt(analysis_id);

	const [hairDensityMode, setHairDensityMode] = useState("all");

	const [validAnalyses, datesOfValidAnalyses] = useMemo(() => {
		const validAnalyses = allAnalysis?.filter((analysis) => {
			return analysis?.images?.['3x_closeup']?.raw?.[subType] ? true : false;
		});
		const datesOfValidAnalyses = validAnalyses?.map((analysis) => {
			return analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.[hairDensityMode];
		})

		return [
			validAnalyses,
			datesOfValidAnalyses
		]
	}, [allAnalysis, hairDensityMode, subType])


	return (
		<div className='flex flex-col'>

			<div class="text-center font-bold text-base leading-5 tracking-wider mt-12 mb-8 capitalize opacity-90" style={{ fontFamily: 'Arial', letterSpacing: '0.5px', color: '#D1D7DA' }}>
				{subType}
			</div>
			<div className="flex items-center justify-center mx-auto mt-10">
				<table className="table-auto border-opacity-40 border border-gray mt-2">
					<tbody>
						<tr>
							<td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
								{translate('hd_a')} :{' '}
								{analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['all']
									? analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['all']
									: ''}
								/cm2
							</td>
							<td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
								{translate('hd_t')} :{' '}
								{analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['t']
									? analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['t']
									: ''}
								/cm2
							</td>
							<td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
								{translate('hd_v')} :{' '}
								{analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['v']
									? analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['v']
									: ''}
								/cm2
							</td>
						</tr>
						<tr>
							<td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
								{translate('t_v')} :{' '}
								{analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['tv']
									? analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['tv']
									: ''}
							</td>
							<td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
								{translate('hd_fu')} :{' '}
								{analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['fu']
									? analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['fu']
									: ''}
								/cm2
							</td>
							<td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
								{translate('ht')} :{' '}
								{analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter']
									? analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter'] * 1000
									: ''}
								mm
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className='mx-16 flex gap-16'>
				<div className='w-1/3'>
					<div className="text-left font-normal text-white text-opacity-90 text-sm leading-5 tracking-wider" style={{ fontFamily: 'Roboto', letterSpacing: '0.8px' }}>
						{translate('hair_density_hd')}
					</div>
					<div className="mx-auto">
						<button
							className={`${hairDensityMode === 'all' && 'text-text-2 '
								} text-sm font-normal focus:outline-none mr-2 font-font-2 `}
							onClick={() => {
								setHairDensityMode('all');
							}}
						>
							A
						</button>
						<button
							className={`${hairDensityMode === 't' && 'text-text-3 '
								} text-sm font-normal focus:outline-none mx-2 font-font-2`}
							onClick={() => {
								setHairDensityMode('t');
							}}
						>
							T
						</button>
						<button
							className={`${hairDensityMode === 'v' && 'text-text-3 '
								} text-sm font-normal focus:outline-none mx-2 font-font-2`}
							onClick={() => {
								setHairDensityMode('v');
							}}
						>
							V
						</button>
						<button
							className={`${hairDensityMode === 'tv' && 'text-text-3 '
								} text-sm font-normal focus:outline-none mx-2 font-font-2`}
							onClick={() => {
								setHairDensityMode('tv');
							}}
						>
							{translate('t_v')}
						</button>
						<button
							className={`${hairDensityMode === 'fu' && 'text-text-3 '
								} text-sm font-normal focus:outline-none mx-2 font-font-2`}
							onClick={() => {
								setHairDensityMode('fu');
							}}
						>
							FU
						</button>
					</div>
					<div className='w-full'>
						<Bar
							id="1"
							data={{
								labels: datesOfValidAnalyses, // array of labels
								datasets: [
									{
										label: '(/cm2)', // label for the dataset
										data: validAnalyses?.map((analysis) => {
											return analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.[hairDensityMode];
										}), // array of data points
										backgroundColor: validAnalyses?.map((val, index) => {
											if (val.id === analysis.id) {
												return '#ef8679';
											}
											return '#7a686f';
										}), // array of background colors
										borderColor: validAnalyses?.map((val, index) => {
											if (val.id === analysis.id) {
												return '#ef8679';
											}
											return '#7a686f';
										}), // array of border colors
										borderWidth: 1, // width of the borders
									},
								],
							}}
							options={{
								plugins: {
									datalabels: {
										display: true,
										color: 'white',
										align: 'end',
										anchor: 'end',
									},
								},

								scales: {
									xAxes: [
										{
											ticks: {
												userCallback: function (l, index) {
													if (l && (
														l.id === analysis.id ||
														index === 0 ||
														index === validAnalyses.length - 1)
													) {
														return l.date;
													}
													return '';
												},
											},
											gridLines: {
												display: true,
												drawBorder: true,
												drawOnChartArea: false,
												color: '#4b535a',
												lineWidth: 2,
											},
										},
									],
									yAxes: [
										{
											ticks: {
												beginAtZero: true,
												userCallback: function (l, index, labels) {
													return l;
												},
												min: 0,
												max: 200,
												stepSize: 100,
											},
											gridLines: {
												display: true,
												drawBorder: true,
												drawOnChartArea: false,
												color: '#4b535a',
												lineWidth: 2,
											},
										},
									],
								},

								legend: {
									labels: {
										boxWidth: -15,
										fontSize: 15,
										fontColor: '#bfc5ca',
									},

									align: 'start',
									onClick: '',
								},
								width: '100%',
								tooltips: {
									callbacks: {
										title: function (tooltipItem, data) {
											return tooltipItem[0]?.xLabel?.date;
										},
									},
								},
							}}
							height='200px'
						/>
					</div>


					<div className="text-left font-normal text-white text-opacity-90 text-sm leading-5 tracking-wider mt-12" style={{ fontFamily: 'Roboto', letterSpacing: '0.8px' }}>
						{translate('hair_thickness_ht')}
					</div>
					<div lassName='w-full'>
						<Bar
							id="2"
							data={{
								labels: datesOfValidAnalyses, // array of labels
								datasets: [
									{
										label: '(µm)', // label for the dataset
										data: validAnalyses.map((analysis) => {
											return analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter'] * 1000;
										}), // array of data points
										backgroundColor: validAnalyses?.map((val, index) => {
											if (val.id === analysis.id) {
												return '#3de2d2';
											}
											return '#267c7f';
										}), // array of background colors
										borderColor: validAnalyses?.map((val, index) => {
											if (val.id === analysis.id) {
												return '#3de2d2';
											}
											return '#267c7f';
										}), // array of border colors
										borderWidth: 1, // width of the borders
									},
								],
							}}
							options={{
								plugins: {
									datalabels: {
										display: true,
										color: 'white',
										align: 'end',
										anchor: 'end',
									},
								},

								scales: {
									xAxes: [
										{
											ticks: {
												userCallback: function (l, index) {
													if (l && (
														l.id === analysis.id ||
														index === 0 ||
														index === validAnalyses?.length - 1)
													) {
														return l.date;
													}
													return '';
												},
											},
											gridLines: {
												display: true,
												drawBorder: true,
												drawOnChartArea: false,
												color: '#4b535a',
												lineWidth: 2,
											},
										},
									],
									yAxes: [
										{
											ticks: {
												beginAtZero: true,
												userCallback: function (l, index, labels) {
													return l;
												},
												min: 0,
												max: 100,
												stepSize: 50,
											},
											gridLines: {
												display: true,
												drawBorder: true,
												drawOnChartArea: false,
												color: '#4b535a',
												lineWidth: 2,
											},
										},
									],
								},

								legend: {
									labels: {
										boxWidth: -15,
										fontSize: 15,
										fontColor: '#bfc5ca',
									},
									align: 'start',
									onClick: '',
								},
								width: '100%',
								tooltips: {
									callbacks: {
										title: function (tooltipItem, data) {
											return tooltipItem[0]?.xLabel?.date;
										},
									},
								},
							}}
							height='200px'
						/>
					</div>
				</div>
				<div className='w-1/3 flex flex-col justify-center align-center'>
					<div class="text-center font-normal text-sm leading-5 tracking-wider text-white opacity-70 mb-10 " style={{ fontFamily: 'Roboto', letterSpacing: '0.8px' }}>
						HD: {analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['all']}/cm2 ({getHairComparisonPercentage(allAnalysis[0]?.images?.['3x_closeup']?.groMeasure?.[subType]?.['all'], analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['all'])}%)
						HT: {analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter'] * 1000}µm ({getHairComparisonPercentage(allAnalysis[0]?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter'], analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter'])}%)
					</div>
					{/* <img src={Closeup} className='cursor-pointer' onDoubleClick={() => {history.push(`/patient/${patient_id}/study/${analysis_id_int}/compare/${subType}`)}}/> */}
					{analysis?.images?.['3x_closeup'].raw && <ImageLoader setReady={setReady} showBlackTint={true} img={analysis?.images?.['3x_closeup']?.raw?.[subType]} secondaryImg={analysis?.images?.['3x_closeup']?.raw?.[subType]?.status !== 'invalidated' ? analysis?.images?.['3x_closeup']?.ml?.[subType] : undefined} onDoubleClick={() => { history.push(`/patient/${patient_id}/study/${analysis_id_int}/compare/${subType}`) }} />}
				</div>
				<div className='w-1/3 flex justify-evenly flex-row'>
					<ShowHeadAvatar />
				</div>
			</div>
		</div>
	)
}

export default ThreexCloseupImageView