import React, { useEffect, useState } from "react";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import GroIndexGraph from "../OnePageConsultComponents/GroIndexGraph";
import ImageLoader from "../ImageLoader";
import ShowHeadAvatar from "../OnePageConsultComponents/ShowHeadAvatar";
import DownArrow from "../../assets/images/downArrowOrange.svg";
import { hairThinningUiData } from "../../data/barGraphData";
import dayjs from "dayjs";
import { useTranslation } from "../../contexts/TranslationContext";

const ClientResultsAssessment = ({
  analysis,
  base_analysis,
  handleTriggerRelaod,
  firstName,
  lastName,
  clientGender,
  produtsRef
}) => {
  const location = useLocation();
  const { mainType, subType } = useParams();
  const { translate } = useTranslation();
  const [overlayImage, setOverlayImage] = useState(null);
  const [hairThinningData, setHairThinningData] = useState({});
  const [closeupAnalysisPresent, setCloseupAnalysisPresent] = useState(null)

  const shouldShowHairThinningData = (hairThinningData && analysis?.images?.[mainType]?.raw?.[subType]);

  const getHairthinningData = () => {
    if (hairThinningData?.romanValue) return "Class " + hairThinningData?.romanValue + " hair loss";
    return translate('your_hair_is_healthy')
  }
  const scrollProductsInToView = () => {
    if (produtsRef?.current) {
      produtsRef?.current?.scrollIntoView({ behavior: "smooth", blocking: "center" })
    }
  }

  useEffect(() => {
    // Set overlay image
    const secondaryImage = analysis?.images?.closeup?.ml?.[subType];
    if (!secondaryImage) {
      setOverlayImage(null);
    } else if (secondaryImage?.length) {
      const requiredImage = secondaryImage?.find((img) => img?.paint === 7);
      setOverlayImage(requiredImage);
    } else {
      setOverlayImage(analysis?.images?.closeup?.ml?.[subType]);
    }

    // Set hair thnning data
    const thinningItem = Object.values(hairThinningUiData).find((level) =>
      mainType === "closeup"
        ? level.markerKey ===
        analysis?.images?.[mainType].hair_thinning?.[subType]?.label
        : level.markerKey === analysis?.images?.[mainType].hair_thinning?.label
    );
    setHairThinningData(thinningItem);
  }, [analysis, location, mainType, subType]);

  useEffect(() => {
    const images = analysis?.images?.closeup?.raw;
    let object = {};
    if (!images) return;
    Object.keys(images)?.forEach(item => {
      if (!object[item]) object[item] = true
    })
    setCloseupAnalysisPresent(object);
  }, [analysis]);

  return (
    <>
      <div style={{ flexGrow: 1 }} className="px-4 sm:px-14">
        <h1
          className="mb-1 sm:mb-3 text-center font-bold text-text-3 text-opacity-85 text-lg leading-5 tracking-widest"
          style={{ letterSpacing: "1.6px" }}
        >
          {`${firstName?.replace(
            firstName[0],
            firstName[0]?.toUpperCase()
          )} ${lastName?.replace(lastName[0], lastName[0]?.toUpperCase())}`}
        </h1>
        <p className="mx-4 text-text-2 text-center font-light">
          {analysis
            ? dayjs.unix(analysis?.created_at).format("MM-DD-YYYY")
            : ""}
        </p>
      </div>
      <div className='text-center font-bold sm:text-lg font-sans tracking-wider opacity-80 text-text-15 mt-6 capitalize mb-0'>
        {subType?.toUpperCase()}
      </div>

      {!analysis?.images?.[mainType]?.raw?.[subType] && (
        <p className="mt-5 xs:mt-1 block h-40 xs:h-80 sm:hidden text-center text-gray-400">
          {translate('no_image_found')}
        </p>
      )}
      <div style={{ flexGrow: 1 }} className="">
        <div className="lg:mx-15 xl:mx-20 grid grid-cols-12 sm:grid-cols-3 gap-4 sm:gap-5 jusitfy-center px-4 sm:px-0">
          <div className="w-full col-span-5 sm:col-span-1 pt-6 sm:pt-7">
            {base_analysis &&
              analysis?.images?.[mainType]?.raw?.[subType]?.status !==
              "invalid" && (
                <GroIndexGraph
                  graphType={"hair_thinning"}
                  analysis={analysis}
                  base_analysis={base_analysis}
                  forType={subType}
                  mainType={mainType}
                  hideGrowthIndex={true}
                />
              )}
          </div>

          <div className="w-full col-span-7 sm:col-span-1 flex justify-center align-center">
            {analysis?.images?.[mainType].raw && (
              <ImageLoader
                img={analysis?.images?.[mainType]?.raw?.[subType]}
                secondaryImg={
                  mainType === "global"
                    ? subType === "crown"
                      ? analysis?.images?.global?.ml?.[subType]
                      : ""
                    : overlayImage
                }
                onDoubleClick={() => { }}
              />
            )}
          </div>

          <div className="col-span-12 block sm:hidden text-center text-text-3 tracking-widest mt-5 mb-7">
            <p className={`text-3xl opacity-90 capitalize leading-relaxed ${!shouldShowHairThinningData && 'invisible'}`}>
              {shouldShowHairThinningData ? getHairthinningData() : translate('no_hair_thinning_data')}
            </p>
            <p className={`text-2xl font-light opacity-80 ${!shouldShowHairThinningData && "invisible"}`}>
              {shouldShowHairThinningData ? hairThinningData?.tolltipDescription?.slice(1, -1) : translate('no_percentage_data')}
            </p>
          </div>

          <div className="col-span-2 block sm:hidden"></div>

          <div className="w-full col-span-8 sm:col-span-1 flex justify-evenly flex-row">
            <ShowHeadAvatar
              handleTriggerRelaod={() => { }}
              analysis={analysis}
              showHead={subType === 'crown' ? 'top' : subType}
              forType={subType}
              mainType={mainType}
              clientGender={clientGender}
              closeupAnalysisPresent={closeupAnalysisPresent}
            />
          </div>

          <div className="col-span-2 block sm:hidden"></div>

          <div className="col-span-3 hidden sm:block text-center text-text-3 tracking-widest my-6 sm:my-2">
            <p className={`text-3xl opacity-90 capitalize leading-relaxed ${!shouldShowHairThinningData && 'invisible'}`}>
              {shouldShowHairThinningData ? getHairthinningData() : translate('no_hair_thinning_data')}
            </p>
            <p className={`text-2xl font-light opacity-80 ${(!shouldShowHairThinningData || !hairThinningData?.tolltipDescription) && "invisible"}`}>
              {(shouldShowHairThinningData && hairThinningData?.tolltipDescription) ? hairThinningData?.tolltipDescription?.slice(1, -1) : translate('no_percentage_data')}
            </p>
          </div>

          <div className="col-span-12 sm:col-span-3 mt-6 sm:mt-2 flex flex-col items-center">
            <h1
              className="text-center font-bold text-text-3 text-opacity-85 text-xl leading-5 tracking-widest"
              style={{ letterSpacing: "1.6px" }}
            >
              GRO Solutions
            </h1>
            <img src={DownArrow} className="h-10 w-10 mt-2 mb-4 cursor-pointer" onClick={scrollProductsInToView} alt="arrow-icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientResultsAssessment;
